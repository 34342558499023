import React from 'react';
import ReactDOM from 'react-dom/client';
import  './Css/global.css';
import App from './App';
import Cookies from 'js-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";



 const registerDevice = async () => {
      try {
        const existingVisitorToken = Cookies.get('visitorToken'); 
        if (existingVisitorToken) {
          console.log('Visitor Token already present:', existingVisitorToken);
          return; 
        }
        var myHeaders = new Headers();
        myHeaders.append("authtoken", "fdd96becb0409c45581e020f9d58fc85");
        myHeaders.append("Content-Type", "application/json");
        const urlParams = new URLSearchParams(window.location.search);
        var raw = JSON.stringify({
          action: "deviceRegister",
          deviceRegister: {
            userAgent: navigator.userAgent,
            deviceId:  "fallbackDeviceId",
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            browser: navigator.userAgent,
            location: '113.5 155.2',
            gsite:urlParams.get('gsite')||"",
          },
        });
  
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };
  
        const response = await fetch("https://api.mytravaly.com/web/v1/", requestOptions);
        if (response.ok) {
          const result = await response.json();
          console.log('visitore token',result);
          if (result.status && result.data && result.data.visitorToken) {
            Cookies.set('visitorToken', result.data.visitorToken, { expires: 30 });
            Cookies.set('visitorCurrency', result.data.currency, { expires: 30 });
            Cookies.set('visitorCountryisoCode', result.data.iso2, { expires: 30 });
            // const visitorToken = Cookies.get('visitorToken');
          } else {
            console.error('Invalid API response format:', result);
          }
        } else {
          console.error('HTTP error! Status:', response.status);
        }
      } catch (error) {
        console.error('Error during fetch:', error);
      }
    }


const startApp = async () => {
  try {
    await registerDevice();
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } catch (error) {
    console.error('Error starting the app:', error);
  }
};

startApp();



